import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React from 'react';
import { withRouter } from "react-router";
import AuthService from '../../services/AuthService';
import CurrentAccountService from "../../services/CurrentAccountService";
import '../../static/css/Login.css';
import logo from '../../static/images/orange.png';



class LoginWithoutRouter extends React.Component {


    state = {
        login: "",
        password: "",
        loggingError: false

    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            loggingError: false
        });
    }

    isPasswordChangeRequired=(data)=>{
        if (data){
            if (data.errorCode==='PASSWORD_CHANGE_REQUIRED'){
                return true;
            }
        }
        return false;
    }


    login = (history) => {
        console.log(`Login:${this.state.login}`);
        AuthService.authenticate(this.state.login, this.state.password,
            (data) => {

                if (this.isPasswordChangeRequired(data)){
                    history.push("/changePassword");
                } else {
                    CurrentAccountService.fetchCurrentUser(()=>{
                        history.push("/");
                    });
                }

            },
            (error) => {
                console.log(`${JSON.stringify(error)}`);
                this.setState({loggingError: true});
            });

    }

    isValid() {

        return this.state.login && this.state.login !== "" && this.state.password && this.state.password !== ""
            && this.state.login.length > 4 && this.state.password.length > 4;
    }

    //https://stackoverflow.com/questions/39442419/reactjs-is-there-a-way-to-trigger-a-method-by-pressing-the-enter-key-inside
    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            if (this.isValid()) {
                this.login(this.props.history);
            }
        }
    }

    render() {
        const {history} = this.props;

        return (
            <div>

                <img src={logo} style={{
                    "display": "block",
                    "marginLeft": "auto",
                    "marginRight": "auto"
                }} alt="logo"/>


                <div className="login-div">

                    <div>
                        <div className="login-input-label">
                            <div className="login-label">
                                <label htmlFor="login">Login</label>
                                <label htmlFor="password">Hasło</label>
                            </div>
                            <div className="login-input">
                                <input placeholder="" id="login" type="text" className="validate" required={true}
                                    onChange={this.handleOnChange}/>
                                <input placeholder="" id="password" type="password" className="validate" required={true}
                                    onChange={this.handleOnChange} onKeyPress={this.enterPressed.bind(this)}/>
                            </div>
                        </div>
                        <div className="login-button">
                            <button type="submit" className="btn waves-effect waves-light"
                                onClick={() => this.login(history)} disabled={!this.isValid()}
                                name="action">Zaloguj się
                            </button>
                        </div>
                        <div className="login-error">
                            <label style={{"color": "red"}} hidden={!this.state.loggingError}>
                                Błąd logowania! Sprawdź poprawność danych.
                            </label>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
const Login = withRouter(LoginWithoutRouter);

export default Login;
