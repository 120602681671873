import {Growl} from 'primereact/growl';
import React from 'react';
import {withRouter} from 'react-router-dom';
import NjuService from "../../services/NjuService";
import '../../static/css/App.css';
import Order from "../other/Order";
import {lastCreationDate, setHistoryMetadata, showMsgAboutOrderAssignmentChange} from '../../shared/Utils';
import NjuOperation from "./NjuOperation";

class NjuOrder extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            technology: {},
            technologyDla: {},
            service: {},
            status: {},
            type: {},
            rejectionReason: {},
            orderDevices: [],
        },
        events: []

    }

    componentDidMount() {
        this._isMounted = true;
        this.getOrder();
    }

    getOrder() {
        let modificationDateTime;
        if (this.state.order) {
            modificationDateTime = this.state.order.modificationDateTime;
        }
        NjuService.getByIdWithTimestamp(this.props.match.params.id, modificationDateTime,
            (data) => {
                showMsgAboutOrderAssignmentChange(data, this.growl, this.state.order);
                if (this._isMounted && data) {
                    setHistoryMetadata(data);
                    this.setState({order: data});
                }

            },
            (error) => {
                console.log(`Error: ${error}`);
            });
    }

    getEvents(id) {
        NjuService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (
            <div className="p-grid p-dir-col nogutter" style={{"paddingTop": "7px"}}>
                <Growl ref={(el) => this.growl = el} position="topright"/>
                <NjuOperation order={this.state.order} refreshOrder={() => this.getOrder()}
                                        refreshEvents={() => this.getEvents(this.state.order.id)}/>
                <Order refreshOrderIntervalMode={true} refreshEventsIntervalMode={true} showOPLNotes={true}
                       dataSource={this.state.order} match={match} refreshOrder={() => this.getOrder()}
                       refreshEvents={() => this.getEvents(this.state.order.id)} events={this.state.events}/>
            </div>
        );

    }
}

export default withRouter(NjuOrder);
