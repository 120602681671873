import RestService from "./RestService";

const OperationService = {

    getByOrderId(id, onSuccess, onError) {

        RestService.get(`/operation/order/${id}`, onSuccess, onError);

    },
};

export default OperationService;

