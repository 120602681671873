import { Button } from 'primereact/button';
import React from 'react';
import OperationService from '../../services/OperationService';
import '../../static/css/App.css';
import '../../static/css/Card.css';
import '../../static/css/Order.css';
import OperationTable from "./OperationTable";


const OpenCloseBtn = ({ showOperations ,onClick}) =>
    <Button icon={!showOperations ? "pi pi-angle-double-down" : "pi pi-angle-double-up"}
        label={showOperations ? "" : "Pokaż operacje"}
        onClick={() => onClick()} />


class Operations extends React.Component {

    state = {
        showOperations: false
    }

    onClickOpenCloseOperations = () => {
        
        let fetchOperations = (!this.state.showOperations) && this.props.order && this.props.order.id;
        this.setState({ showOperations: !this.state.showOperations },()=>{
            if(fetchOperations){
                OperationService.getByOrderId(this.props.order.id,
                    (data) => {
                        this.setState({ items: data });
                    }
                    , () => {
                    });
            }
        });
    }
    
    render() {
        const { header } = this.props
        return (
            <>
                {!this.state.showOperations
                    ? <div className="section">
                        <OpenCloseBtn showOperations={this.state.showOperations} onClick={this.onClickOpenCloseOperations}  /> 
                    </div> 
                    :
                    <div className="section">
                        <div className="sectionHeader">
                            <span style={{ "marginRight": "10px" }}>{header}</span>
                            <OpenCloseBtn showOperations={this.state.showOperations} onClick={this.onClickOpenCloseOperations}  /> 
                        </div>

                        <div className="rTable">
                            {<OperationTable dataSource={() => this.state.items} />}
                        </div>
                    </div>
                }</>
        );
    }
}

export default Operations;