import { discard, OrderStatus, resumedToInstaller } from "../../config/constants";

const MonitoringOperationUtil = {


    showMonitoringOperation(order){
        const status = order.status.name;
        return status === OrderStatus.PT_QUESTIONS_FOR_THE_CONTRACT || status === OrderStatus.PT_RESIGNATION
            || status === OrderStatus.PT_CLIE_POSTPON
            || status === OrderStatus.PT_TECH_POSTPONED
            || status === OrderStatus.PT_NO_RESPONSE
            || status === OrderStatus.PT_LATE
            || status === OrderStatus.PT_WAITING_FOR_CLI;

    },

    isOperationShouldBeAdded(order){
        const status = order.status.name;
        return status === OrderStatus.PT_QUESTIONS_FOR_THE_CONTRACT || status === OrderStatus.PT_RESIGNATION
            || status === OrderStatus.PT_CLIE_POSTPON
            || status === OrderStatus.PT_TECH_POSTPONED
            || status === OrderStatus.PT_NO_RESPONSE
            || status === OrderStatus.PT_WAITING_FOR_CLI;

    },

    getOperations(order) {

        const operations = [];
        if (this.isOperationShouldBeAdded(order)) {
            operations.push(resumedToInstaller);
            operations.push(discard);
        }
        return operations;
    },

    isAnyOperationAvailable(order) {
        return this.getOperations(order).length > 0;
    }
};

export default MonitoringOperationUtil;
