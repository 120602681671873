import { EventState } from "../config/constants";


const EventStateMapper = {


    mapToVerboseName(type) {
        if (type) {
            switch (type) {
                case EventState.ACKNOWLEDGED:
                    return "Odpisanie od zlecenia";
                case EventState.COMMITTED:
                    return "Przekazanie do dyspozytora";
                case EventState.INIT_AUTO:
                    return "Przypisanie się do zlecenia przez automat";
                case EventState.INIT_MANUAL:
                    return "Przypisanie się do zlecenia";
                case EventState.REJECTED:
                    return "Odrzucenie";
                case EventState.TO_MANUAL:
                    return "Przekazanie na kolejkę manualną";
                default:
            }
        }

        return "Brak";
    },




};

export default EventStateMapper;
