import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from 'react';
import { withRouter } from "react-router";
import EventStateMapper from '../../shared/EventStateMapper';
import { DateTimeTemplate } from "../../shared/OrderTableCommon";


class OperationTableWithRouter extends React.Component {


    state = {}

    getDatasource() {
        if (this.props.dataSource) {
            return this.props.dataSource();
        }
        return [];
    }

    render() {


      

        return (

                <DataTable className={this.props.dataTableClassName} ref={(el) => this.dt = el}
                    value={this.getDatasource()} 
                    autoLayout={true}>

                    <Column field="id" header="Id" body={(rowData) => {
                        return <div>
                            <a>{rowData.id}</a>
                        </div>;
                    }}  />
                    <Column field="eventState" header="Zdarzenie" 
                        body={(rowData) => {
                            return <div>
                                <div>{rowData.eventState}</div>
                                {EventStateMapper.mapToVerboseName(rowData.eventState)}
                            </div>;
                        }} />
                    <Column field="status" header="Status" />

                    <Column field="creationDateTime" body={DateTimeTemplate} header="Utworzono" />
                    <Column field="modificationDateTime" body={DateTimeTemplate} header="Zmodyfikowano"/>
                    <Column field="nextAttemptDateTime" body={DateTimeTemplate} header="Data następnej próby" />
                    <Column field="numberOfAttempts"  header="Liczba prób" />
                    <Column  header="Aktywny" 
                        body={(rowData) => {
                            return <div>
                                <div>{rowData.active?"Tak":"Nie"}</div>
                            </div>;
                        }} />
                    <Column  header="Odpowiedz z atrium" 
                        body={(rowData) => {
                            return <div>
                                 {rowData.responseStatus && <div>{"Status: " + rowData.responseStatus}</div>}
                                 {rowData.responseDescription && <div>{"Opis: " + rowData.responseDescription}</div>}
                                 {rowData.responseCorrelationId && <div>{"CorrelationId: " + rowData.responseCorrelationId}</div>}
                            </div>;
                        }} />    

                </DataTable>
        );
    }
}

const OperationTable = withRouter(OperationTableWithRouter);
export default OperationTable;
