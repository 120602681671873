import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Messages} from "primereact/messages";
import React from 'react';
import {withRouter} from 'react-router-dom';
import {
    discard,
    forwardFromDsToOkiActivation,
    forwardToDispatcher,
    OrderStatus,
    rejectAndForwardToInstaller,
    resumedToInstaller
} from "../../config/constants";
import CurrentAccountService from "../../services/CurrentAccountService";
import DealerSupportService from "../../services/DealerSupportService";
import ErrorService from "../../services/ErrorService";
import AssignPanel from "../../shared/AssignPanel";
import OrderRejectionReasonDiv from "../../shared/OrderRejectionReasonDiv";
import '../../static/css/App.css';
import classNames from "classnames";


class DealerSupportOperation extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedOperation: "",
            comment: "",
            orderRejectionReason: null,
            disabled: false

        };
    }


    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }


    getOperations(order) {

        const status = order.status.name;

        const operations = [];

        if (status === OrderStatus.DEALER_SUPPORT_NEW || status === OrderStatus.BOT_OKI_BACK_TO_DS
            || status === OrderStatus.BOT_DS_BACK_TO_OPL || status === OrderStatus.SELL_CPE_NEW
            || status === OrderStatus.OKI_ACTIVATION_TO_DS || status === OrderStatus.BOT_DS_UNASSIGNED) {
            operations.push(forwardToDispatcher);
            operations.push(rejectAndForwardToInstaller);
            operations.push(forwardFromDsToOkiActivation);
        } else if (status === OrderStatus.PT_QUESTIONS_FOR_THE_CONTRACT || status === OrderStatus.PT_RESIGNATION
            || status === OrderStatus.PT_CLIE_POSTPON
            || status === OrderStatus.PT_TECH_POSTPONED
            || status === OrderStatus.PT_NO_RESPONSE) {
            operations.push(resumedToInstaller);
            operations.push(discard);
        }

        return operations;
    }


    invokeOperation = (orderId) => {
        this.setState({disabled: true}, function () {
            let data = {};
            if (this.state.comment) {
                if(this.state.comment.length > 180) {
                    this.showError("Zbyt długi komentarz!", "Maksymalna liczba znaków w komentarzu to 180.");
                    return;
                }
                data = {comment: this.state.comment};
            }

            if (this.state.selectedOperation) {
                if (this.state.selectedOperation.key === forwardToDispatcher["key"]) {
                    this.forwardToDispatcher(orderId, data);
                } else if (this.state.selectedOperation.key === rejectAndForwardToInstaller["key"]) {
                    if (this.state.orderRejectionReason) {
                        data["orderRejectionReasonId"] = this.state.orderRejectionReason["key"];
                    }
                    this.rejectAndForwardToInstaller(orderId, data);
                } else if (this.state.selectedOperation.key === resumedToInstaller["key"]) {
                    this.resumeOrderAndForwardToInstaller(orderId, data);
                } else if (this.state.selectedOperation.key === discard["key"]) {
                    this.discard(orderId, data);
                } else if (this.state.selectedOperation.key === forwardFromDsToOkiActivation["key"]) {
                    this.forwardFromDsToOkiActivation(orderId, data);
                }
            }
        });
    }


    forwardToDispatcher(orderId, data) {
        DealerSupportService.forwardToDispatcher(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    redirectOnSuccess() {
        // this.props.history.push("/dealerSupport/order");
        this.props.history.goBack();
    }

    resumeOrderAndForwardToInstaller(orderId, data) {
        DealerSupportService.resumeOrderAndForwardToInstaller(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    discard(orderId, data) {
        DealerSupportService.discard(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    rejectAndForwardToInstaller(orderId, data) {
        DealerSupportService.rejectAndForwardToInstaller(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    forwardFromDsToOkiActivation(orderId, data) {
        DealerSupportService.forwardFromDsToOkiActivation(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }


    isOPLUserAssignedToOrder() {
        return this.props.order.assignedOplUser;
    }

    isCurrentOPLUserAssignedToOrder() {
        if (this.props.order.assignedOplUser) {
            let login = this.props.order.assignedOplUser.login;
            return CurrentAccountService.getLogin() === login;
        }

    }

    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (
            <div className="p-grid nogutter p-col-4 p-component p-align-start p-col-align-start">

                {this.getOperations(this.props.order).length > 0 ?
                    <>
                        <AssignPanel refreshOrder={this.props.refreshOrder} order={this.props.order}/>

                        {this.isCurrentOPLUserAssignedToOrder() ?
                            <>
                                <Messages ref={(el) => this.messages = el}/>
                                <div className="p-grid nogutter p-col-12">
                                    <label className="p-col-3 o-label" htmlFor="in">Przekaż zlecenie:</label>
                                    <span className="o-value">
                                        <Dropdown showClear={true}
                                                  optionLabel="name"
                                                  value={this.state.selectedOperation}
                                                  options={this.getOperations(this.props.order)}
                                                  onChange={(event) => {
                                                      this.setState({selectedOperation: event.value});
                                                  }}/>
                                    </span>
                                </div>

                                <OrderRejectionReasonDiv selectedOperation={this.state.selectedOperation}
                                                         onChange={(event) => {
                                                             this.setState({orderRejectionReason: event.value});
                                                         }
                                                         }/>

                                <div className="p-grid nogutter p-col-12">
                                    <label
                                        className={classNames("p-col-3", "o-label", {"p-error": this.state.comment.length > 180})}>Komentarz:</label>
                                    <span className="o-value p-float-label">
                                        <InputTextarea id={"commentTextArea"} rows={4} cols={30} autoResize={true}
                                                       value={this.state.comment}
                                                       className={classNames({"p-invalid": this.state.comment.length > 180})}
                                                       onChange={(e) => {
                                                           this.setState({comment: e.target.value})
                                                       }}/>
                                        <label
                                            htmlFor="commentTextArea">{this.state.comment ? this.state.comment.length + "/180" : ""}</label>
                                    </span>
                                </div>

                                <div className="p-grid nogutter p-col-12 p-justify-start">
                                    <span className="p-col-3 o-label"/>
                                    <Button disabled={this.state.disabled || !this.state.selectedOperation}
                                            style={{"marginLeft": "10px", "marginTop": "5px"}}
                                            className="o-value" onClick={() => {
                                        this.invokeOperation(match.params.id);
                                    }} label="Przekaż"/>
                                </div>
                            </>
                            : null}
                    </>
                    : null}
            </div>
        );

    }
}

export default withRouter(DealerSupportOperation);
