import _ from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import React from 'react';
import ReactTooltip from "react-tooltip";
import {
    ADMIN_ROLE_KEY, CHAT_NOTIFICATION_ALLOWED_ROLES,
    ERROR_MESSAGE_TITLE,
    NO_AREA,
    NO_DATA,
    OPEN_CHAT_ERROR_MESSAGE_DETAIL,
    OrderStatus,
    REFRESH_EVENTS_INTERVAL,
    REFRESH_TABLE_INTERVAL
} from "../../config/constants";
import ChatService from "../../services/ChatService";
import CurrentAccountService from "../../services/CurrentAccountService";
import ErrorService from "../../services/ErrorService";
import FinishedWorkService from "../../services/FinishedWorkService";
import MonitoringService from "../../services/MonitoringService";
import OrderEventUtil from "../../services/OrderEventUtil";
import OrderService from "../../services/OrderService";
import {
    BooleanRow, ChatStatusLabel,
    copyToClipboard,
    copyValueToClipboard,
    DateTimeRow,
    StringRow,
    StringRowChatButton,
    StringRowWithCopyBtn,
    StringRowWithDistinction,
    TextAreaRow
} from "../../shared/CardCommon";
import InterventionStatusMapper from "../../shared/InterventionStatusMapper";
import OplStatusMapper from "../../shared/OplStatusMapper";
import { AIdTemplate, DateTimeTemplate, TextAreaRowTemplate } from "../../shared/OrderTableCommon";
import { createFirstNameAndLastNameLoginSt, DateTimeField, OrderUtil, sortByDate } from "../../shared/Utils";
import '../../static/css/App.css';
import '../../static/css/Card.css';
import '../../static/css/Order.css';
import ActivationHistory from "./ActivationHistory";
import DeviceTable from "./DeviceTable";
import Operations from "./Operations";
import OPLNotesTable from "./OPLNotesTable";
import OrderEventTable from "./OrderEventTable";

import CallPtDialog from "./CallPtDialog";
import AssignBeforeOpenChatDialog from "./AssignBeforeOpenChatDialog";
import AvailableAssignmentService from "../../shared/AvailableAssignmentService";
import {withRouter} from "react-router-dom";

function getDeltaWithObject(deltaName, object) {

    const order = object;
    if (order && order.events && order.events.length > 0) {
        const delta = OrderEventUtil.getDeltas(order)[deltaName];
        return delta || delta === 0 ? `${delta} min` : null;
    }

}

const DeviceCodeRow = ({ label, value, onClick }) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className="rTableCell">
            <span id={"deviceCodeSpanId"}>{value}</span>
            <button className="p-button pi pi-copy" onClick={onClick} />
        </div>
    </div>;


const AreaRow = ({ label, value }) => {
    if (value === NO_AREA) {
        return (
            <div className="rTableRow">
                <div className="rTableCell argumentHeader">{label}</div>
                <div className="rTableCell noData">{NO_DATA}</div>
            </div>
        )
    } else {
        return (
            <div className="rTableRow">
                <div className="rTableCell argumentHeader">{label}</div>
                <div className="rTableCell">{value}</div>
            </div>
        )
    }
}

const LocationRow = ({ label, value }) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className={value ? "rTableCell" : "rTableCell noData"}>{value ? value : NO_DATA}</div>
    </div>;

const TechnologyDlaRow = ({ label, value, activationCounter }) =>
    <div className="rTableRow">
        <div className="rTableCell argumentHeader">{label}</div>
        <div className={activationCounter > 0 && (value ? "rTableCell" : "rTableCell noData")}>
            {activationCounter > 0 && (value ? value : NO_DATA)}
        </div>
    </div>;

const InstallerRow = ({ label, value, onMakePhoneCall }) => {
    OrderUtil.installers(value);
    let installersAddedToOrderEvents = _.get(value, "orderEventAggregatedData.installers", []);
    let dateTimeByInstallerId = installersAddedToOrderEvents.reduce(function (map, obj) {
        map[obj.id] = obj.addedToOrderDateTime;
        return map;
    }, {});

    const installers = value.installers ? value.installers : [];
    installers.map(item => {
        // const creationDateTime = OrderEventUtil.getCreationDateTimeFromInstallerAddedToOrder(value.events, item.id);
        return item.creationDateTime = dateTimeByInstallerId[item.id];
    })
    sortByDate(installers, "creationDateTime");

    if (OrderUtil.isCreatedDuringActivation(value) && OrderUtil.isEmpty(installers)) {
        return (
            <div className="rTableRow">
                <div className="rTableCell argumentHeader">{label}</div>
                <div className="rTableCell">
                    <span className="noData">{NO_DATA}</span>
                </div>
            </div>);
    } else {
        return (
            <div className="rTableRow">
                <div className="rTableCell argumentHeader">{label}</div>
                <div className="rTableCell">
                    {installers.map(item => {
                        return <div>
                            <span data-tip="" data-for={item.identifier}
                                  className={"defaultCursor"}>{OrderUtil.prepareInstallerText(item)}</span>
                            <ReactTooltip id={item.identifier} place="top" effect="float">
                                {item.creationDateTime && <DateTimeField dateTimeString={item.creationDateTime} />}
                            </ReactTooltip>
                            <button style={{ "marginLeft": "0.15em" }} className="p-button pi pi-copy"
                                onClick={() => copyValueToClipboard(item.identifier)} />
                            <button style={{ "marginLeft": "0.15em" }} className="call-button pi pi-phone"
                                    onClick={(e) => {
                                        onMakePhoneCall(item.id);
                                    }} />
                        </div>;
                    }
                    )}

                </div>
            </div>);
    }
};

const CompletedByRow = ({ label, value }) => {

    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">{label}</div>
            <div className="rTableCell">
                { value?
                    <div>
                        <span>{OrderUtil.prepareInstallerText(value)}</span>
                        <button style={{"marginLeft": "0.15em"}} className="p-button pi pi-copy"
                                onClick={() => copyValueToClipboard(value.identifier)}/>
                    </div>
                    :null

                }
            </div>
        </div>);
}

const TimeBetweenOrderCompletedAndOplAssigmentBodyRow = ({ label, value, onClick }) => {
    let time = _.get(value, "timeBetweenOrderCompletedAndOplAssigment");
    time = time || time === 0 ? time + " min" : null;
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">{label}</div>
            <div className="rTableCell">{time}</div>
        </div>);
}

const TimeBetweenOplAssigmentAndForwardedToDispatcherBodyRow = ({ label, value, onClick }) => {
    let time = _.get(value, "timeBetweenOplAssigmentAndForwardedToDispatcher");
    time = time || time === 0 ? time + " min" : null;
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">{label}</div>
            <div className="rTableCell">{time}</div>
        </div>);
}

const TimeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBodyRow = ({ label, value, onClick }) => {
    let time1 = _.get(value, "timeBetweenOrderCompletedAndOplAssigment");
    let time2 = _.get(value, "timeBetweenOplAssigmentAndForwardedToDispatcher");
    let time = time1 + time2
    time = time || time === 0 ? time + " min" : null;
    return (
        <div className="rTableRow">
            <div className="rTableCell argumentHeader">{label}</div>
            <div className="rTableCell">{time}</div>
        </div>);
}


const Basic = ({ item, header, style, refreshOrder, onMakePhoneCall }) =>

    <div className="section" style={style}>
        <div className="sectionHeader">{header}
            {refreshOrder && <Button icon="pi pi-refresh" style={{
                'marginLeft': '5px', 'width': '1.6em', 'height': '1.6em',
                'verticalAlign': 'middle'
            }} onClick={() => refreshOrder()} />
            }
        </div>
        <div className="rTable">
            <div className="rTableRow">
                <div className="rTableCell argumentHeader">{"Id usługi:"}</div>
                <div className="rTableCell">
                    <span id="neoid-span">{item.neoId}</span>
                    <button style={{ "marginLeft": "0.15em" }} className="p-button pi pi-copy"
                        onClick={() => copyValueToClipboard(item.neoId.match(/\d{12}$/))} />
                </div>
            </div>
            <StringRowWithCopyBtn label={"Numer zlecenia:"} value={item.number} />
            <StringRowWithCopyBtn label={"Numer zamówienia:"} value={item.externalOrderId} />
            <StringRow label={"Przypisany użytkownik:"}
                value={createFirstNameAndLastNameLoginSt(item.assignedOplUser)} />

            <InstallerRow
                onMakePhoneCall={(installerId) => {
                    onMakePhoneCall(installerId);
                }}
                label={"Serwisant:"} value={item} />
            {/* https://git.chupacabra.tp.pl/photomontage/deadpool-backend/-/issues/38 */}
            {/* <StringRow label={"Firma:"} value={getCompanyFromOrderArea(item)} /> */}
            <AreaRow label={"Strefa:"} value={item.area ? item.area.verboseName : null} />

            <LocationRow label={"Adres:"} value={item.location} />
            <StringRow label={"Właściciel sieci (UKE ID):"} value={item.networkOwner ? item.networkOwner.verboseName+" ("+(item.networkOwner.ukeId ? item.networkOwner.ukeId : "brak") +")" : ""} />
            <StringRow label={"Operator zamawiający:"} value={item.serviceOperator ? item.serviceOperator.verboseName : ""} />
            <DateTimeRow label={"Data umówienia:"} value={item.timeSlot} />
            <StringRow label={"Technologia:"} value={item.technology ? item.technology.verboseName : ""} />
            <TechnologyDlaRow label={"Technologia DLA:"} value={item.technologyDla ? item.technologyDla.verboseName : ""}
                              activationCounter={item.activationCounter}/>
            <StringRow label={"Usługa:"} value={item.service ? item.service.verboseName : ""} />
            <StringRow label={"Opcja usługowa:"} value={item.serviceOption ? item.serviceOption.verboseName : ""} />

            <DateTimeRow label={"Utworzono:"} value={item.creationDateTime} />
            <DateTimeRow label={"Przekazano do OPL:"} value={item.completionDateTime} />
            <DateTimeRow label={"Zmodyfikowano:"} value={item.modificationDateTime} />

            <TimeBetweenOrderCompletedAndOplAssigmentBodyRow
                label={"Czas oczekiwania na podjęcie zlecenia przez użytkownika OPL:"}
                value={item} />
            <TimeBetweenOplAssigmentAndForwardedToDispatcherBodyRow
                label={"Czas od podjęcia zlecenia do przekazania dyspozytorowi:"}
                value={item} />
            <TimeBetweenCompleteOrderByInstallerAndForwardedToDispatcherBodyRow
                label={"Łączny czas obsługi zlecenia:"}
                value={item} />

            <StringRow label={"Obsłużył w OPL:"}
                value={createFirstNameAndLastNameLoginSt(item.handledByUser)} />

            <StringRow label={"FM Id:"} value={item.id} />
            <StringRow label={"Status:"} value={item.status.verboseName} />

            {/* <StringRow label={"Czy PT jest w lokalu Klienta:"}
                value={typeof item.ptInClientLocation !== "undefined" ?
                    item.ptInClientLocation.ptInClientLocation === true ? PtInClientLocation.IN.name : PtInClientLocation.OUT.name
                    : PtInClientLocation.NO_INFORMATION.name} /> */}
  
            <StringRow label={"Status zakończenia interwencji:"} disabled={true}
                value={InterventionStatusMapper.mapToVerboseName(_.get(item, "orderEventAggregatedData.finishedWork.success")) } />

            <DateTimeRow label={"Data zakończenia interwencji:"}
                value={_.get(item, "orderEventAggregatedData.finishedWork.creationDateTime")} />

            <TextAreaRow label={"Komentarz:"} value={item.comment} />

            <StringRow label={"Powód odrzucenia zgłoszenia:"}
                value={item.rejectionReason ? item.rejectionReason.verboseName : ""} />
            <BooleanRow disabled={true} label={"Orange love:"} value={item.isOrangeLove} />

            <StringRow label={"Status DS:"}
                value={OplStatusMapper.mapToVerboseName(item.oplStatus)} />

        </div>
    </div>;

const tooltipText = "Wartość określa czy ponowny fotomontaż został wysłany po zatwierdzeniu";


const LastAtriumOperation = ({ order }) => {

    let lastAtriumOper = _.get(order, "orderEventAggregatedData.lastAtriumOperation");

    return (
        <>
            {lastAtriumOper && <StringRow label={"Status przesłania informacji do systemu ATRIUM:"}
                value={ (lastAtriumOper["success"] === true ? "Sukces" : "Błąd")} />
            }
        </>
    );

}

const Important = ({ item, header, style, refreshOrder, mainDivClass, hasUnreadMessages, openChat }) =>
    <div className={mainDivClass ? mainDivClass : "section"} style={style}>
        <div className="sectionHeader">{header}
            {refreshOrder && <Button icon="pi pi-refresh" style={{
                'marginLeft': '5px', 'width': '1.6em', 'height': '1.6em',
                'verticalAlign': 'middle'
            }} onClick={() => refreshOrder()} />
            }
        </div>
        <div className="rTable" style={{ display: "contents" }}>
            <StringRow label={"Typ:"}
                value={item.type ? item.type.verboseName : ""} />
            <StringRowWithCopyBtn label={"Status umowy:"}
                value={item.contractStatus ? item.contractStatus.verboseName : ""} />
            <StringRowChatButton order={item} hasUnreadMessages={hasUnreadMessages} onClick={openChat} />
            <ChatStatusLabel order={item} hasUnreadMessages={hasUnreadMessages} />
            {typeof item.activateAfterConfirmation !== 'undefined' &&
                <StringRowWithDistinction label={"Czy ponowny fotomontaż?:"}
                    value={item.activateAfterConfirmation === true ? "TAK" : "NIE"}
                    valueClassName={item.activateAfterConfirmation === true ? "distinctionTableCell" : "rTableCell"}
                    tooltipText={tooltipText} />}
            <LastAtriumOperation order={item} />
            <StringRow label={"Marka:"} value={item.brandName} />
        </div>
    </div>;

class Devices extends React.Component {

    state = {
        rows: 100,
        showHistoryActivationHistory: false,
    }

    render() {
        const { item, header, hasUnreadMessages, openChat } = this.props;

        return (
            <div className="section">
                <Important mainDivClass={"section-level1"} item={item} header="Ważne"
                    hasUnreadMessages={hasUnreadMessages} openChat={openChat} />
                <div className="section-level1">

                    <div className="sectionHeader">{header}

                    </div>
                    <div className="rTable">
                        {item.deviceCode &&
                            <DeviceCodeRow label={"Sprzęt:"} value={item.deviceCode}
                                onClick={() => copyToClipboard("deviceCodeSpanId")} />
                        }
                    </div>
                    <div className="rTable">
                        <DeviceTable devices={item.orderDevices} rows={this.state.rows}
                            metadata={item.metadata ? item.metadata["activation"] : ""}
                            showHistoryActivationHistory={true} />
                    </div>

                    {item && !_.isEmpty(item.activationHistory) &&
                        <div className="container">
                            <Button type="button"
                                icon={!this.state.showHistoryActivationHistory ? "pi pi-angle-double-down" : "pi pi-angle-double-up"}
                                style={{ 'float': 'left', 'margin': '2px' }}
                                label={this.state.showHistoryActivationHistory ? "Ukryj historię aktywacji" : "Pokaż historię aktywacji"}
                                onClick={(e) => this.showHistoryActivation()} />
                        </div>}

                    {item.activationHistory && item.activationHistory.length > 0 && this.state.showHistoryActivationHistory &&
                        <ActivationHistory data={item.activationHistory} header={"Historia aktywacji"}
                            metadata={item.metadata ? item.metadata.historyActivation : ""}
                            showHistoryActivationHistory={this.state.showHistoryActivationHistory} />}
                </div>
            </div>
        );
    }

    showHistoryActivation() {
        this.setState({
            showHistoryActivationHistory: !this.state.showHistoryActivationHistory,
        })
    }

}

class Delays extends React.Component {

    state = {
        rows: 100,
        entries: []
    }

    componentDidMount() {
        MonitoringService.getDelays(this.props.item.id, (data) => {
            this.setState({ "entries": data.entries });
        }, () => {
        });
    }

    render() {
        const { header } = this.props;

        return (
            <div className="section">
                <div className="sectionHeader">{header}</div>
                <div className="rTable">
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.entries} rows={this.state.rows}

                        first={this.state.first} autoLayout={true}
                    >
                        <Column field="installer.identifier" header="Serwisant" />
                        <Column field="creationDateTime" body={DateTimeTemplate} header="Data" />
                        <Column field="delayInMinutes" header="Opóźnienie" body={(rowData, column) => {
                            return (
                                <div>{rowData[column.field]}min</div>
                            );
                        }} />


                    </DataTable>
                </div>
            </div>
        );
    }
}


class FinishedWorks2 extends React.Component {

    state = {
        rows: 100,
        items: []
    }

    componentDidMount() {

        FinishedWorkService.getByOrderId(this.props.orderId,
            (data) => {
                this.setState({ items: data });
            }
            , () => {
            });
    }


    render() {
        const { header } = this.props;

        return (
            <>
                {
                    Array.isArray(this.state.items) && this.state.items.length > 0 &&
                    <div className="section">
                        <div className="sectionHeader">{header}</div>
                        <div className="rTable">
                            <DataTable
                                value={this.state.items} rows={this.state.rows}
                                first={this.state.first} autoLayout={true}
                            >
                                <Column field="id" header="Id" body={AIdTemplate} />
                                <Column field="creationDateTime" header="Data zakończenia" body={DateTimeTemplate} />
                                <Column field="success" header="Status" body={(rowData, column) => {
                                    return (
                                        <> {rowData[column.field] === true ? "Skuteczna" : "Nieskuteczna"}</>
                                    );
                                }} />
                                <Column field="installer" header="Serwisant" body={(rowData, column) => {
                                    return (
                                        <> {`${rowData[column.field]["firstName"]} ${rowData[column.field]["lastName"]}`}</>
                                    );
                                }} />
                                <Column field="comment" header="Komentarz"
                                    body={TextAreaRowTemplate}
                                />

                            </DataTable>
                        </div>
                    </div>
                }
            </>
        );

    }
}

const isCurrentOPLAssignedToOrder = (order) => {
    if (order) {
        const assignedOplUser = order.assignedOplUser;
        if (assignedOplUser) {
            return CurrentAccountService.getId() === assignedOplUser.id;
        }
    }

    return false;
};


class OplNotes extends React.Component {

    state = {
        areaText: ""
    }

    render() {
        const { item, header, onNewNote } = this.props;
        return (
            <div className="section">
                <div className="sectionHeader">{header}
                    <text style={{ color: 'red', 'fontWeight': 'normal' }}>(Wersja BETA)</text>
                </div>
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <span style={{ "width": "100%", "marginRight": "2px", "marginTop": "5px", "marginLeft": "2px" }}>
                        <InputTextarea value={this.state.areaText} style={{ "width": "100%" }} maxLength="250"
                            autoResize={true}
                            onChange={(e) => this.setState({ areaText: e.target.value })} />
                    </span>
                    <span style={{
                        "display": "flex", "alignItems": "center",
                        "marginRight": "2px"
                    }}>
                        <Button
                            label={"Dodaj notatkę"}
                            disabled={!this.state.areaText || !isCurrentOPLAssignedToOrder(item)}
                            onClick={() => {
                                OrderService.addOPLNote(item.id, this.state.areaText, () => {
                                    if (onNewNote) {
                                        onNewNote();
                                        this.setState({ areaText: "" });
                                    }
                                }, () => {
                                });
                            }} />
                    </span>
                </div>
                <div className="rTable">
                    <OPLNotesTable dataSource={() => item.events} />
                </div>
            </div>
        );
    }
}

class Events extends React.Component {

    _isMounted = false;

    state = {
        showHistory: false,
        events: []
    }

    render() {
        const { events, header, refreshEvents } = this.props
        return (

            <>
                {!this.state.showHistory
                    ? <Button icon={!this.state.showHistory ? "pi pi-angle-double-down" : "pi pi-angle-double-up"}
                        label={this.state.showHistory ? "Ukryj historię" : "Pokaż historię"}
                        style={{ marginBottom: "25px" }}
                        onClick={() => {
                            if (!this._isMounted) {
                                refreshEvents();
                                this._isMounted = true;
                                this.resetInterval();
                            }
                            this.setState({ showHistory: !this.state.showHistory });
                        }} />

                    :
                    <div className="section">
                        <div className="sectionHeader">
                            <span style={{ "marginRight": "10px" }}>{header}</span>
                            <Button
                                icon={!this.state.showHistory ? "pi pi-angle-double-down" : "pi pi-angle-double-up"}
                                tooltip={"Ukryj historię"}
                                onClick={() => this.setState({ showHistory: !this.state.showHistory })} />
                            <Button icon="pi pi-refresh" tooltip={"Odśwież historię"} style={{
                                'marginLeft': '8px', 'width': '2.4em', 'height': '2.4em'
                            }} onClick={() => refreshEvents()} />
                        </div>
                        <div className="rTable">
                            {this.state.showHistory && <OrderEventTable events={events} />}
                        </div>
                    </div>


                }


            </>
        );
    }

    componentWillUnmount() {
        this._isMounted = false
        clearInterval(this.interval);
    }

    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.props.refreshEvents(), REFRESH_EVENTS_INTERVAL);
    }

}



class Order extends React.Component {

    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    constructor(props) {
        super(props);


        this.state = {
            order: {
                area: [],
                installers: [],
                technology: {},
                technologyDla: {},
                service: {},
                status: {},
                type: {},
                rejectionReason: {},
                orderDevices: [],
                contractStatus: {},
                assignedOplUser: {},
                handledByUser: {},
                ontppMappingStatus: {},
                assignedOplUsers: [],
                dialogVisible: false,
                phoneNumber: {},
            },
            deltas: {}
        };


    }

    componentDidMount() {
        this._isMounted = true;

        if (!this.props.dataSource) {
            this.fetchOrder();
        } else {
            this.setState({ order: this.props.dataSource });
        }

        if (this.props.refreshOrderIntervalMode) {
            this.resetInterval();
        }

        this.getChatInfo();

    }

    resetInterval() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.refreshOrder();
            this.getChatInfo();
        }, REFRESH_TABLE_INTERVAL);
    }

    fetchOrder() {
        OrderService.getById(this.props.match.params.id,
            (data) => {
                if (this._isMounted) {
                    this.setState({ order: data });
                }

            },
            (error) => {
                console.log(`Error: ${error}`);
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    getObject() {
        return this.props.dataSource ? this.props.dataSource : this.state.order;
    }

    getDelta(deltaName) {

        const order = this.getObject();
        return getDeltaWithObject(deltaName, order);

    }

    refreshOrder() {
        if (this.props.refreshOrder) {
            this.props.refreshOrder();
        }
    }

    makePhoneCall(installerId) {
        this.setState({ makeCallDialogVisible: true , installerId : installerId});
    }

    createChat(rowData) {
        this.setState({
            rowData: rowData
        }, () => {

            const orderId = rowData.id;
            if (this.isOplAssignOrNoNeedToAssign(rowData)) {
                // Gdy jest przypisany lub na danej kolejce nie można się przypisać do zlecenia - rób jak było
                ChatService.chat(orderId, (errorData) => {
                    let msg =ErrorService.mapResponseBodyToErrorTexts(errorData,{});
                    this.showError(msg.title, msg.msg);
                }).then(() => {
                    this.setState({
                        notificationMap: ChatService.hideBadge(this.state.notificationMap || {}, orderId)
                    });
                    this.refreshOrder();
                });
            } else {
                // Gdy nie jest przypisany
                this.setState({
                    assignDialogVisible: true
                })
            }

        })
    }

    isOplAssignOrNoNeedToAssign(rowData) {
        let availableAssignmentService = new AvailableAssignmentService();
        return (OrderUtil.isCurrentOPLActuallyAssignedToOrder(rowData)
            || availableAssignmentService.isNoNeedToAssign(this.props.location.search, rowData, this.props.monitoringPreviewOrBots)
        );
    }

    

    showError( title,  msg) {
        if(title && msg){
            this.messages.show({ severity: 'error', summary: title, detail: msg });
        }else{
            this.messages.show({ severity: 'error', summary: ERROR_MESSAGE_TITLE, detail: OPEN_CHAT_ERROR_MESSAGE_DETAIL });
        }
        
    }

    getChatInfo() {
        if (CurrentAccountService.areAllowedRolesInCurrentAccountRoles(CHAT_NOTIFICATION_ALLOWED_ROLES)) {
            ChatService.getNotificationInfo([this.props.match.params.id], data => {
                this.setState({
                    notificationMap: data.reduce((map, item) => ({ ...map, [item.orderId]: item }), {})
                });
            }, error => ErrorService.logServerError(error));
        }
    }

    hasUnreadMessages() {
        const orderId = this.props.match.params.id;
        return !!this.state.notificationMap && !!this.state.notificationMap[orderId] ? this.state.notificationMap[orderId].showBadge : false;
    }

    refreshEvents() {
        if (this.props.refreshEvents) {
            this.props.refreshEvents();
        }
    }

    render() {
        const { visible, showOPLNotes } = this.props;
        return (
            <>
                <Messages ref={(el) => this.messages = el} />
                <div className="container">
                    <Basic style={{ minWidth: "30%", maxWidth: "35%" }} visible={visible} item={this.getObject()}
                        header="Zlecenie" refreshOrder={() => this.refreshOrder()}
                           onMakePhoneCall={(installerId) => this.makePhoneCall(installerId)}/>
                    <div className="container" style={{ "width": "64%" }}>
                        <Devices item={this.getObject()} header={"Urządzenia"}
                            hasUnreadMessages={this.hasUnreadMessages()}
                            openChat={() => this.createChat(this.getObject())} />
                        {this.props.showFinishedWorkList &&
                            <FinishedWorks2 orderId={this.props.match.params.id} header={"Zakończenia interwencji:"} />
                        }
                        {showOPLNotes && <OplNotes header={"Notatki OPL"} item={this.getObject()} onNewNote={() => {
                            this.refreshOrder();
                        }} />
                        }
                        {this.getObject().status.name === OrderStatus.PT_LATE &&
                            <Delays item={this.getObject()} header={"Opóźnienia"} />
                        }
                        {CurrentAccountService.hasRole(ADMIN_ROLE_KEY) && <Operations order={this.getObject()} header={"Operacje"} />}
                    </div>
                    
                </div>

                <div className="container">
                    <Events events={this.props.events} header={"Historia:"} refreshEvents={() => this.refreshEvents()} />
                </div>

                <CallPtDialog visible={this.state.makeCallDialogVisible}
                              installerId={this.state.installerId} oplNumber={this.state.oplNumber}  onHide={() => this.setState({ makeCallDialogVisible: false })}
                              onSuccess={() => this.setState({makeCallDialogVisible: true})}>
                </CallPtDialog>
                <AssignBeforeOpenChatDialog visible={this.state.assignDialogVisible} assign={this.props.assign}
                                            rowData={this.state.rowData} refreshOrder={this.props.refreshOrder}
                                            parent={this} notificationMap={this.state.notificationMap}
                                            onHide={() => this.setState({ assignDialogVisible: false })}
                                            onSuccess={() => this.setState({ assignDialogVisible: true })}>
                </AssignBeforeOpenChatDialog>
            </>
        );

    }
}

export default withRouter(Order);
